import emailValidator from 'email-validator'

const Reducer = (state, action) => {
  const user = {...state.user}
  const formErrors = {...state.formErrors}

  switch (action.type) {
    case "TOGGLE_SUBMENU":
      return {...state, openMenu: !state.openMenu}
    case "UPDATE_PAYMENT_STEP":
      return {...state, paymentStep: action.payload }
    case "UPDATE_PAYMENT_PROCESSING":
      return {...state, paymentProcessing: action.payload}
    case "SET_READY":

      return {...state, ready: action.payload}
    case "SEND_FORM":
      return {...state, sent: true}

    case "UPDATE_NAME":
      user.name = action.payload

      if (formErrors.name) {
        formErrors.name = false
      }

      return {...state, user, formErrors}
    case "UPDATE_EMAIL":
      let mail = action.payload

      user.email = action.payload
      formErrors.email = !emailValidator.validate(mail);

      return {...state, user, formErrors}
    // case "UPDATE_PASSWORD":
    //   // Add validation
    //   user.password = action.payload
    //
    //   if (formErrors.password) {
    //     formErrors.password = false
    //   }
    //
    //   return {...state, user, formErrors}
    case "UPDATE_BIRTHDAY":
      user.birthDay = action.payload

      return {...state, user}

    case "SET_GATEWAY":
      return {...state, paymentGateway: action.payload}

    case "SUCCESS_PAYMENT_PROCESSING":
      if (action.payload === true) {
        return {...state, paymentProcessing: true, paymentSuccess: true}
      }
      return state
    case "FORM_ERRORS":
      formErrors.email = !emailValidator.validate(user.email);
      formErrors.name = user.name.length < 1;
      // formErrors.password = user.password.length < 5;

      let element = document.getElementById('top-half');
      element && element.scrollIntoView({ behavior: "smooth", block: "start" });

      return {...state, formErrors};
    default:
      return state;
  }
};

export default Reducer;
