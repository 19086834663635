import React, { createContext, useReducer } from "react";
import Reducer from "./reducer";
const initialState = {
  openMenu: null,
  ready: false,
  sent: false,
  showFromErrors: false,
  showSubMenu: false,
  formErrors: {
    name: false,
    email: false,
    // password: false,
    birthDay: false,
  },
  user: {
    name: '',
    email: '',
    // password: '',
    birthDay: null,
  },
  paymentStep: 1,
  paymentProcessing: false,
  paymentGateway: 'stripe',
  paypalObj: null,
  stripeObj: null,
  paymentSuccess: null,
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  return (
    <Context.Provider value={
      [state, dispatch]
    }>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);
export default Store;
